<template>
  <div>
    <v-navigation-drawer
      v-model="drawer"
      app
      clipped
      :dark="barColor !== 'rgba(228, 226, 226, 1), rgba(255, 255, 255, 0.7)'"
      color="#002441"
    >
      <v-list shaped nav dense>
        <div v-for="(link, i) in links" :key="i">
          <v-list-item
            v-if="!link.subLinks"
            :to="link.to"
            exact-active-class="red accent-2"
            link
          >
            <v-list-item-icon>
              <v-icon>{{ link.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-title v-text="link.text" />
          </v-list-item>

          <v-list-group
            v-else
            :key="link.text"
            no-action
            :prepend-icon="link.icon"
            :value="false"
            color="white"
            active-class="red accent-2"
          >
            <template v-slot:activator>
              <v-list-item-title color="white">{{
                link.text
              }}</v-list-item-title>
            </template>

            <v-list-item
              v-for="sublink in link.subLinks"
              :to="sublink.to"
              :key="sublink.text"
              link
              active-class="red lighten-1"
            >
              <v-list-item-icon>
                <v-icon>{{ sublink.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-title>{{ sublink.text }}</v-list-item-title>
            </v-list-item>
          </v-list-group>
        </div>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar app clipped-left>
      <v-toolbar-title class="mr-12 align-center">
        <div>
          <img
            v-if="!loading"
            :src="
              company.logo_url
                ? company.logo_url
                : require(`@/assets/new_logo.png`)
            "
            height="50px"
          />
        </div>
      </v-toolbar-title>
      <v-icon size="30" class="ml-6" @click.stop="drawer = !drawer"
        >mdi-menu</v-icon
      >
      <v-spacer></v-spacer>
      <div>
        <v-menu offset-y :close-on-content-click="false" v-model="menu">
          <template v-slot:activator="{ on, attrs }">
            <v-btn elevation="0" dark v-bind="attrs" v-on="on">
              Hey, {{ Logged_user.user_email }}
            </v-btn>
          </template>
          <v-list>
            <v-list-item>
              <v-list-item-title>
                <v-card elevation="0">
                  <v-form ref="form">
                    <v-text-field
                      color="black"
                      outlined
                      dense
                      label="Old Password"
                      v-model="old_password"
                      type="password"
                    ></v-text-field>
                    <v-text-field
                      color="black"
                      outlined
                      dense
                      label="New Password"
                      v-model="new_password"
                      type="password"
                    ></v-text-field>
                    <v-text-field
                      color="black"
                      outlined
                      dense
                      label="Confirm Password"
                      v-model="confirm_password"
                      type="password"
                    ></v-text-field>
                  </v-form>
                  <v-btn
                    color="#FF7743"
                    block
                    elevation="0"
                    @click.stop="changePassword"
                    ><p class="mb-0 text-capitalize">Change password</p></v-btn
                  >
                </v-card>
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
      <div class="pa-2 pr-3">
        <v-btn text @click="logout">logout</v-btn>
      </div>
    </v-app-bar>

    <v-snackbar v-model="snackbar" centered :color="color">
      {{ text }}
    </v-snackbar>
  </div>
</template>
<script>
// Utilities
import { mapState } from "vuex";

export default {
  name: "supportdashboardCoreDrawer",
  check: false,

  props: {
    expandOnHover: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    loading: true,
    old_password: "",
    new_password: "",
    confirm_password: "",
    snackbar: false,
    text: "",
    menu: false,
    items: [
      {
        status: "comment",
        title: "this is comment",
      },
      {
        status: "support",
        title: "this is support",
      },
      {
        status: "marchent",
        title: "this is marchant",
      },
    ],
    notify: "",
    color: "warning",

    drawer: null,
    Logged_user: {},

    links: [
      {
        to: "/supportdashboard",
        icon: "mdi-view-dashboard",
        text: "Dashboard",
      },
      {
        to: "/supportdashboard/pages/order",
        icon: "mdi-format-list-checks",
        text: "Orders",
      },
      // {
      //   to: "/supportdashboard/pages/pos_order",
      //   icon: "mdi-printer-eye",
      //   text: "POS Orders",
      // },
      {
        icon: "mdi-shopping",
        text: "Product Management",
        subLinks: [
          {
            text: "All",
            to: "/supportdashboard/pages/productdetails",
            icon: "mdi-cart",
          },

          // {
          //   text: "Group Products",
          //   to: "/supportdashboard/pages/group-products",
          //   icon: "mdi-select-group",
          // },
          // {
          //   text: "Flash Sale",
          //   to: "/supportdashboard/pages/flash_sale",
          //   icon: "mdi-weather-lightning",
          // },
          // {
          //   text: "Imported",
          //   to: "/supportdashboard/pages/imported_product",
          //   icon: "mdi-cart-arrow-down",
          // },
          // {
          //   text: "Shared",
          //   to: "/supportdashboard/pages/report",
          //   icon: "mdi-cart-arrow-up",
          // },
          // {
          //   text: "Mother Site",
          //   to: "/supportdashboard/pages/mother-products",
          //   icon: "mdi-earth-arrow-right",
          // },

          {
            text: "Unpublished",
            to: "/supportdashboard/pages/unpublished-products",
            icon: "mdi-television-off",
          },
          // {
          //   text: "Shareable",
          //   to: "/supportdashboard/pages/unshared-products",
          //   icon: "mdi-share-all",
          // },
          {
            text: "All Brands",
            to: "/supportdashboard/pages/brand",
            icon: "mdi-tag-text",
          },
        ],
      },
      // {
      //   to: "/supportdashboard/pages/mother-invoices",
      //   icon: "mdi-file-table-outline",
      //   text: "Purchase Invoices",
      // },
      // {
      //   icon: "mdi-clipboard-text-outline",
      //   text: "Report",
      //   subLinks: [
      //     // {
      //     //   text: "Imported Product",
      //     //   to: "/supportdashboard/pages/mothersite-report",
      //     //   icon: "mdi-text-box-search-outline",
      //     // },
      //     {
      //       text: "Sales Report",
      //       to: "/supportdashboard/pages/salesreport",
      //       icon: "mdi-text-box-search-outline",
      //     },
      //     {
      //       text: "Warehouse Report",
      //       to: "/supportdashboard/pages/warehousereport",
      //       icon: "mdi-text-box-search-outline",
      //     },
      //     {
      //       text: "Shop Report",
      //       to: "/supportdashboard/pages/shopreport",
      //       icon: "mdi-text-box-search-outline",
      //     },
      //     // {
      //     //   text: "Merchant Report",
      //     //   to: "/supportdashboard/pages/merchantreport",
      //     //   icon: "mdi-text-box-search-outline",
      //     // },
      //     // {
      //     //   text: "Product Purchase Report",
      //     //   to: "/supportdashboard/pages/daily_report",
      //     //   icon: "mdi-text-box-search-outline",
      //     // },
      //     // {
      //     //   text: "Product Stock Report",
      //     //   to: "/supportdashboard/pages/stock_report",
      //     //   icon: "mdi-text-box-search-outline",
      //     // },
      //     // {
      //     //   text: "POS Report",
      //     //   to: "/supportdashboard/pages/pos_report",
      //     //   icon: "mdi-text-box-search-outline",
      //     // },
      //   ],
      // },
      {
        icon: "mdi-cash",
        text: "Expense",
        subLinks: [
          {
            text: "All Expenses",
            to: "/supportdashboard/pages/expenses",
            icon: "mdi-cash-register",
          },
          {
            text: "Create Voucher",
            to: "/supportdashboard/pages/create-voucher",
            icon: "mdi-cash-plus",
          },
          {
            text: "Net Profit",
            to: "/supportdashboard/pages/expense-report",
            icon: "mdi-file-chart",
          },
          {
            text: "My Expenses",
            to: "/supportdashboard/pages/all-expenses",
            icon: "mdi-account-cash",
          },
        ],
      },
      {
        icon: "mdi-cog-outline",
        text: "Settings",
        subLinks: [
          {
            text: "FAQ",
            to: "/supportdashboard/pages/faq",
            icon: "mdi-frequently-asked-questions",
          },
          {
            text: "Banner",
            to: "/supportdashboard/pages/banner",
            icon: "mdi-ticket",
          },
          {
            text: "Advertisement",
            to: "/supportdashboard/pages/advertisement",
            icon: "mdi-billboard",
          },
          {
            text: "Warehouse & Shop",
            to: "/supportdashboard/pages/warehouse",
            icon: "mdi-warehouse",
          },
        ],
      },
      // {
      //   icon: "mdi-api",
      //   text: "API",
      //   subLinks: [
      //     {
      //       text: "CRM",
      //       to: "/support/pages/api/crm",
      //       icon: "mdi-message-text",
      //     },
      //     {
      //       text: "Delivery & Courier",
      //       to: "/support/pages/api/delivery",
      //       icon: "mdi-truck-delivery-outline",
      //     },
      //   ],
      // },
      {
        icon: "mdi-lightbulb-on",
        text: "Support",
        subLinks: [
          {
            text: "Support ticket",
            to: "/supportdashboard/pages/supports",
            icon: "mdi-comment-text-multiple-outline",
          },
          {
            text: "All Notes",
            to: "/supportdashboard/pages/notes",
            icon: "mdi-notebook-check",
          },

          {
            text: "Chat",
            to: "/supportdashboard/pages/chat",
            icon: "mdi-chat",
          },

          {
            text: "Find invoice",
            to: "/supportdashboard/pages/find-invoice",
            icon: "mdi-file-search-outline",
          },
        ],
      },
    ],

    company: {},
  }),

  computed: {
    ...mapState(["barColor", "barImage"]),
    computedItems() {
      return this.items.map(this.mapItem);
    },
    profile() {
      return {
        avatar: true,
        title: this.$t("avatar"),
      };
    },
  },

  methods: {
    changePassword() {
      if (
        this.old_password != "" ||
        this.new_password != "" ||
        this.confirm_password != ""
      ) {
        let payload = {
          old_password: this.old_password,
          new_password: this.new_password,
          confirm_password: this.confirm_password,
        };

        axios
          .post(`user/change_password/${this.Logged_user.user_id}/`, payload)
          .then((response) => {
            if (response.data.success) {
              this.$refs.form.reset();
              this.menu = false;
              this.text = "Password updated successfully";
              this.color = "success";
              this.snackbar = true;
            } else {
              this.text = response.data.message;
              this.snackbar = true;
            }
          })
          .catch((err) => {
            this.menu = false;
            this.text = "Something went wrong !";
            this.snackbar = true;
          });
      } else {
        this.text = "Please provide valid information";
        this.snackbar = true;
      }
    },
    setRoute(item) {
      if (item.status == "comment") {
        this.$router.push("/supportdashboard/pages/supports");
      }
      if (item.status == "support") {
        this.$router.push("/supportdashboard/pages/supports");
      }
      if (item.status == "marchent") {
        this.$router.push("/supportdashboard/pages/invoices");
      }
    },
    mapItem(item) {
      return {
        ...item,
        children: item.children ? item.children.map(this.mapItem) : undefined,
        title: this.$t(item.title),
      };
    },

    logout() {
      localStorage.removeItem("user");
      this.$router.push("/");
    },
  },

  mounted() {
    this.Logged_user = JSON.parse(localStorage.getItem("user"));
    if (this.Logged_user.role == "Admin") {
      this.check = true;
    }
  },

  created() {
    // Get company info
    axios
      .get("site/info/")
      .then((response) => {
        this.loading = false;
        if (response.data.success && response.data.data.length) {
          this.company = response.data.data[0];
        }
      })
      .catch((err) => {
        this.loading = false;
      });
  },
};
</script>

<style scoped>
.v-application--is-ltr
  .v-list-group--no-action
  > .v-list-group__items
  > .v-list-item {
  padding-left: 20px !important;
}
.color {
  background-color: #854fff;
}
.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
}

.dropdown:hover .dropdown-content {
  display: block;
}
</style>
